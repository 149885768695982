import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ApolloProvider, ApolloClient, InMemoryCache, gql, useMutation, HttpLink } from "@apollo/client";
import ContactFromDate from "data/sections/form-info.json";

const JobModal = ({ oppturtunityData, ...props }) => {
  const APOLLO_CLIENT = new ApolloClient({
    link: new HttpLink({ uri: process.env.GATSBY_API_URL }),
    cache: new InMemoryCache()
  });

  // GraphQL mutation query
  const CREATE_JOB_APPLICATION = gql`
    mutation CreatejobApplicationMutation($clientMutationId: String!, $fullName: String!, $email: String!, $contact: String!, $exp: String!, $current_Dsg: String!, $current_Loc: String!, $file: Upload) {
      createJobapplication(input: {clientMutationId: $clientMutationId, fullName: $fullName, email: $email, contact: $contact, exp: $exp, current_Dsg: $current_Dsg, current_Loc: $current_Loc, file: $file}) {
        success
        data
      }
    }
  `;

  const FormData = () => {
    const [fullNameValue, setFullNameValue] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [contactValue, setContactValue] = useState("");
    const [expValue, setExpValue] = useState("");
    const [currentDesignation, setCurrentDesignation] = useState("");
    const [currentLocation, setCurrentLocation] = useState("");
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitMessage, setSubmitMessage] = useState(""); // Holds the success or failure message

    // Handle file change
    const handleFileChange = (e) => {
      const uploadedFile = e.target.files[0];
      console.log("File selected:", uploadedFile);

      const allowedFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

      // Validate file type
      if (uploadedFile && !allowedFileTypes.includes(uploadedFile.type)) {
        setFileError("Invalid file type. Please upload a PDF or Word document.");
        setFile(null); // Clear previous file selection
      } else {
        setFile(uploadedFile);
        setFileError(""); // Clear any previous error
      }
    };

    // Custom function to handle file upload
    const handleSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitting(true);
      setSubmitMessage("");
    
      console.log("Form submission started");
    
      // Construct the form data payload
      const formData = new window.FormData();
      console.log("FormData object created:", formData);
    
      formData.append("operations", JSON.stringify({
        query: CREATE_JOB_APPLICATION.loc.source.body,
        variables: {
          clientMutationId: "abc123",  // Hardcoded mutation ID
          fullName: fullNameValue,
          email: emailValue,
          contact: contactValue,
          exp: expValue,
          current_Dsg: currentDesignation,
          current_Loc: currentLocation,
          file: null // Placeholder for file
        },
      }));
      console.log("Operations appended to FormData:", formData);
    
      formData.append("map", JSON.stringify({ "1": ["variables.file"] }));
      console.log("Map appended to FormData:", formData);
    
      formData.append("1", file);
      console.log("File appended to FormData:", file);
    
      try {
        const response = await fetch(process.env.GATSBY_API_URL, {
          method: "POST",
          body: formData,
        });
    
        // Log the response content type
        console.log("Response Content-Type:", response.headers.get("Content-Type"));
    
        // If the response is not JSON, log the body of the response
        const responseBody = await response.text(); // Get the raw response text
        console.log("Response Body:", responseBody);  // Log the raw response text
    
        if (response.ok) {
          const result = JSON.parse(responseBody); // Manually parse JSON if valid
          console.log("Response result:", result);
    
          if (result.errors) {
            setSubmitMessage("Failed to submit your application. Please try again later.");
          } else {
            setSubmitMessage("Your application has been successfully submitted. Thank you!");
          }
        } else {
          setSubmitMessage(`An error occurred. HTTP Status: ${response.status}. Please try again later.`);
        }
      } catch (error) {
        console.error("Submission error:", error);
        setSubmitMessage("An error occurred while submitting. Please try again later.");
      } finally {
        setIsSubmitting(false); // Reset loading state
      }
    };
    
   

    return (
      <>
        {oppturtunityData && oppturtunityData.length > 0 && (
          <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                {/* Add Title if needed */}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="job-form" onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-field">
                  <input type="text" name="fullname" id="fullname" required onChange={(event) => {
                    setFullNameValue(event.target.value);
                    console.log("Full Name entered:", event.target.value);
                  }} />
                  <label htmlFor="fullname">Full Name</label>
                </div>
                <div className="form-field">
                  <input id="email" type="email" name="email" required onChange={(event) => {
                    setEmailValue(event.target.value);
                    console.log("Email entered:", event.target.value);
                  }} />
                  <label htmlFor="email">Email</label>
                </div>
                <div className="form-field">
                  <input type="tel" name="contact" id="contact" required onChange={(event) => {
                    setContactValue(event.target.value);
                    console.log("Phone number entered:", event.target.value);
                  }} />
                  <label htmlFor="contact">Phone</label>
                </div>
                <div className="exp form-field">
                  <select value={expValue} required onChange={(event) => {
                    setExpValue(event.target.value);
                    console.log("Experience selected:", event.target.value);
                  }}>
                    <option disabled value="">Select Experience</option>
                    <option value="0-6 months">0-6 months</option>
                    <option value="6 months-1 year">6 months-1 year</option>
                    <option value="1-2 years">1-2 years</option>
                    <option value="2-3 years">2-3 years</option>
                    <option value="3+ years">3+ years</option>
                  </select>
                </div>
                <div className="form-field">
                  <input type="text" name="current_Dsg" id="current_Dsg" required onChange={(event) => {
                    setCurrentDesignation(event.target.value);
                    console.log("Current Designation entered:", event.target.value);
                  }} />
                  <label htmlFor="current_Dsg">Current Designation</label>
                </div>
                <div className="form-field">
                  <input type="text" name="current_Loc" id="current_Loc" required onChange={(event) => {
                    setCurrentLocation(event.target.value);
                    console.log("Current Location entered:", event.target.value);
                  }} />
                  <label htmlFor="current_Loc">Current Location</label>
                </div>

                <div className="form-field">
                  <input type="file" name="file" id="file" required onChange={handleFileChange} />
                  {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                </div>

                <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Submit Application"}
                </button>
                <Button className="btn btn-danger" onClick={props.onHide}>Close</Button>
              </form>
              {/* Success/Failure Message */}
                {submitMessage && (
                  <div style={{ marginTop: '20px', color: submitMessage.includes('success') ? 'green' : 'red' }}>
                    {submitMessage}
                  </div>
                )}
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        )}
      </>
    );
  };

  return (
    <ApolloProvider client={APOLLO_CLIENT}>
      <FormData />
    </ApolloProvider>
  );
};

export default JobModal;
