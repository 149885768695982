import React, { useEffect, useState } from "react";
import { GetOppturtunities } from "components/ApiServices/GetOppturtunitiesData";
import { Button } from "react-bootstrap";
import JobModal from "components/JobModal";

const Oppturtunities = () => {
    const oppturtunitiesData=GetOppturtunities()
    const [selectedFilters, setSelectedFilters] = useState(new Set());
    const [modalShow, setModalShow] = useState(false);
    const [selectedOpportunity,setSelectedOppurtunity]= useState(false);  


    // useEffect(()=>{
    //     $('#exampleModal').on('show.bs.modal', function (event) {
    //         var button = $(event.relatedTarget) // Button that triggered the modal
    //         var recipient = button.data('whatever') // Extract info from data-* attributes
    //         // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    //         // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    //         var modal = $(this)
    //         modal.find('.modal-title').text('New message to ' + recipient)
    //         modal.find('.modal-body input').val(recipient)
    //       })
    // }, []);

    const handleFilterChange=(location) => {
        setSelectedFilters((prevFilters) => {
        const newFilters =new Set(prevFilters);
        newFilters.has(location)?newFilters.delete(location):newFilters.add(location);
        return newFilters;
        });
    };

    const filteredOppturtunities=oppturtunitiesData?.filter((oppturtunity)=>{
    const passesFilter = selectedFilters.size === 0 || selectedFilters.has(oppturtunity?.node?.jobLocation);
    return passesFilter;
    
    });


    return(
        <>
        <section className="oppturtunities-section">
            <div className="container oppturtunities-container">
                <div className="row">
                    {/* <div className="col-md-3 col-sm-4">
                        <div className="filters">
                            <h5>Filters</h5>
                            <div className="pb-3 my-2">
                                <p>
                                    <button className="btn btn-primary filter-title" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                        Filter Title
                                    </button>
                                </p>

                                <div className="collapse" id="collapseExample">
                                    {oppturtunitiesData && oppturtunitiesData.length > 0 && Array.from(new Set(oppturtunitiesData.map((oppturtunity)=>oppturtunity?.node?.jobLocation || ""))
                                    ).map((location)=>(
                                        
                                        <li key={location} className="d-flex p-0 flex-row card card-body filter-item">
                                        <input 
                                        type="checkbox" 
                                        id={location} 
                                        name={location}
                                        checked={selectedFilters.has(location)}
                                        onChange={()=>handleFilterChange(location)}
                                        />
                                            <label className="filter-label" htmlfor={location}>
                                                {location}
                                            </label>
                                        </li>
                                        
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-md-12">
                        <h3>Why work with evolvan</h3>
                        <p>Evolvan is not just a company, it's a community of creators, innovators, and dreamers. We believe in the power of technology to transform lives, and we're on a mission to make that happen. We're looking for individuals who share our passion for innovation and want to be part of something bigger than themselves. <br />

                        At Evolvan, we value open communication, collaboration, and diversity. We believe that everyone has a unique perspective to bring to the table, and we're committed to creating an environment where everyone feels valued and supported. We're constantly pushing the boundaries of what's possible, and we're looking for people who want to do the same. <br />

                        If you're looking for a company that values your ideas, encourages your growth, and challenges you to be your best, then Evolvan is the place for you. Join us, and together, we'll change the world.</p>

                        <br />

                        <h3 id="oppurtunities">Oppurtunities</h3>
                        <br />
                        {/* {filteredOppturtunities?.map((oppturtunity)=>(
                        <div key={oppturtunity?.node?.id} className="job-info flex-nowrap d-flex justify-content-between py-3 my-2">
                            <div className="job-title">
                                {oppturtunity?.node?.jobTitle}
                            </div>

                            <div classNameName="location">
                                {oppturtunity?.node?.jobLocation}
                            </div>
                        </div>
                        ))} */}

                        <div className="accordion" id="accordionExample">
                        {filteredOppturtunities?.map((oppturtunity,i)=>(
                        <div className="card">
                            <div key={oppturtunity?.node?.id} className="card-header job-info flex-nowrap d-flex justify-content-between py-3" id={`heading${i}`} data-toggle="collapse" data-target={`#collapse${i}`} aria-expanded={i === 0} aria-controls={`collapse${i}`}>
                                <div className="job-title">
                                    {oppturtunity?.node?.jobTitle}
                                </div>

                                <div classNameName="location">
                                    {oppturtunity?.node?.jobLocation}
                                </div>
                            </div>

                            <div id={`collapse${i}`} className={`collapse`} aria-labelledby={`heading${i}`} data-parent="#accordionExample">

                            <div className="card-body py-2">
                                <div className="job-desc" dangerouslySetInnerHTML={{__html: oppturtunity?.node?.jobDescription}} ></div>
                                <Button variant="primary" className="nb butn bord curve mt-30 apply-btn" onClick={() => setModalShow(true)} >
                                    Apply
                                </Button>
                            </div>
                            </div>
                        </div>
                        ))}
                        </div>

                        <div className="container-sm w-50 mx-auto my-5" >
                            <h3>Join the team</h3>
                            <p>Join us to work on exciting projects and shape the future with the latest technologies.</p>
                            <center>
                            <a href="#oppurtunities" className="nb butn bord curve mt-30 apply-btn mx-auto">Apply Now</a>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <JobModal
            oppturtunityData={[oppturtunitiesData]}
            show={modalShow}
            onHide={() => setModalShow(false)}
        />
        </>
    )
}
export default Oppturtunities