import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";

const LightboxContainer = styled.div`
  padding:50px 0px;
  column-gap: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const LightboxDialog = styled(Dialog)`
  width: 40%;
`;

const PreviewButton = styled.button`
  max-width: 300px;
  width: 100%;
`;

const LightboxDialogOpen = styled(LightboxDialog)
`transition:none;`;

export default class Lightbox extends Component {
  static propTypes = {
    galleryImages: PropTypes.array.isRequired, // eslint-disable-line
  };

  constructor(props) {
    super(props);

    this.state = {
      showLightbox: false,
      selectedImage: null,
    };
  }

  render() {
    const { galleryImages } = this.props;
    const { selectedImage, showLightbox } = this.state;
    return (
      <Fragment>
        <LightboxContainer>
          {galleryImages &&
            galleryImages.map((image) => (
              <PreviewButton
                key={image.node.childImageSharp.gatsbyImageData.src}
                type="button"
                onClick={() =>
                  this.setState({ showLightbox: true, selectedImage: image })
                }
              >
                <GatsbyImage image={image.node.childImageSharp.gatsbyImageData} loading ="lazy" />
              </PreviewButton>
            ))}
        </LightboxContainer>
        {showLightbox && (
          <LightboxDialog>
            <GatsbyImage
              image={selectedImage.node.childImageSharp.gatsbyImageData}
              loading ="lazy" />
            <button
              type="button"
              onClick={() => this.setState({ showLightbox: false })}
            >
              Close
            </button>
          </LightboxDialog>
        )}
      </Fragment>
    );
  }
}
