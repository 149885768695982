import { graphql, useStaticQuery } from "gatsby"

export const GetOppturtunities =()=>{
    const data = useStaticQuery(graphql`
    query{
        wpgraphql{
            oppturtunites {
                edges {
                    node {
                        jobTitle
                        jobLocation
                        jobDescription
                    }
                }
            }
        }
    }
    `);
    return data.wpgraphql.oppturtunites.edges;
}