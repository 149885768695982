import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import particlesConfig from "config/particle-config";

const ParticalsOverlay = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);
  return (
    <>
      <Particles
        // id="tsparticles"
        id="particles-js"
        init={particlesInit}
        options={particlesConfig}
        style={{
          pointerEvents: "none",
        }}
      />
    </>
  );
};

export default ParticalsOverlay;
